import { useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import { getActiveEnrolledCourses } from 'pages/Dashboard/ducks/slice';

import StyledTabs from 'components/Tabs';
import Loader from 'elements/Loader';
import Feedback from 'pages/Dashboard/components/Feedback';
import Achievements from 'pages/Dashboard/components/Achievements';
import About from 'pages/Profile/About';
import SelfAssessments from 'pages/Dashboard/components/SelfAssessments';

import { getPlatformName, getUserName } from 'utils/utility';
import { useGetProfileDetailQuery } from 'api/profileApi';

import { useIsSpeechCraftMFE, useRoles } from 'hooks';

import useProfilePage from './useProfilePage';
import ProfileHeader from './ProfileHeader';
import Transcript from './Transcript';
import { ProfileTabsContainer } from './style';

const Profile = () => {
  const dispatch = useDispatch();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const { isCoordinator } = useRoles();
  const { clubId, username, isMemberProfilePage, isSelfProfilePage } =
    useProfilePage();
  const user = getAuthenticatedUser();

  const { data, isLoading } = useGetProfileDetailQuery({
    username,
  });

  const baseRoute = isMemberProfilePage
    ? isSpeechCraftMFE
      ? `${getPlatformName()}/my-cohort/profile/${username}`
      : `${getPlatformName()}/my-clubs/profile/${clubId}/${username}`
    : `${getPlatformName()}/profile`;

  const tabs = useMemo(() => {
    const allTabs = [
      {
        ariaControls: 'about',
        label: 'About',
        id: 'about',
        component: <About aboutData={data} />,
        isActive: true,
      },
      {
        ariaControls: 'transcript',
        label: 'Transcript',
        id: 'transcript',
        component: (
          <Transcript
            transcriptTitle={`${getUserName(
              isMemberProfilePage ? data?.user : user,
            )} Progress`}
          />
        ),
        isActive:
          isSpeechCraftMFE &&
          (isSelfProfilePage ? !isCoordinator : isCoordinator),
      },
      {
        ariaControls: 'achievements',
        label: 'Achievements',
        id: 'achievements',
        component: <Achievements />,
        isActive: true,
      },
      {
        ariaControls: 'feedback',
        label: isSpeechCraftMFE ? 'Communications' : 'Feedback',
        id: 'feedback',
        component: <Feedback memberName={getUserName(data?.user)} />,
        isActive: true,
      },
      {
        ariaControls: 'self-Assessments',
        label: 'Self-Assessments',
        id: 'self-Assessments',
        component: <SelfAssessments />,
        isActive:
          isSpeechCraftMFE &&
          (isSelfProfilePage ? !isCoordinator : isCoordinator),
      },
    ];

    return allTabs.filter(({ isActive }: any) => isActive);
  }, [
    data,
    isCoordinator,
    isMemberProfilePage,
    isSelfProfilePage,
    isSpeechCraftMFE,
    user,
  ]);

  useEffect(() => {
    if (isSpeechCraftMFE) {
      return;
    }

    dispatch(
      getActiveEnrolledCourses({ username, order_by: 'course__display_name' }),
    );
  }, [dispatch, isSpeechCraftMFE, username]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <ProfileHeader profileData={data} />
      <ProfileTabsContainer>
        <StyledTabs tabs={tabs} defaultTab='about' baseRoute={baseRoute} />
      </ProfileTabsContainer>
    </Box>
  );
};

export default Profile;
