import { FC, useState } from 'react';
import { generatePath, Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  Block,
  Container,
  IconHolder,
  NavBreadCrumbs,
  SelectionBlock,
  SelectionBoxes,
  SelectionBottomBlock,
  RequestFormHolder
} from './style';
import { toast } from 'react-toastify';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from 'elements/Button';
import { ReactComponent as SendEvaluation } from '../../assets/images/send-evaluation.svg';
import { ReactComponent as ShareEvaluation } from '../../assets/images/share-evaluation.svg';
import Avatar from 'elements/Avatar';
import ShareFormModal from 'pages/Dashboard/components/Feedback/EvaluationFeedback/ShareFormModal';
import { useAppContext } from 'contexts/AppContext';
import { useIsSpeechCraftMFE } from 'hooks';
import { getPlatformName, getStringWithSeperator, getUserName, parseDate } from 'utils/utility';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { shareEvaluationForm } from 'pages/Dashboard/ducks/evaluationFormsSlice';
import { useDispatch } from 'react-redux';
import { DashboardRoutes as Routes } from 'routes';


const EvaluationFormSelectionSection: FC<any> = () => {
  const { title } = useParams<any>();
  const [showRequestForm, setShowRequestForm] = useState(false);
  const history = useHistory();
  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const dispatch = useDispatch<any>();
  const { search, state: routerState = {} } = useLocation<any>();

  const handleSubmitForm = async (
    isDirectFeedback = false,
    values: any,
    updatedValues: any,
    setSubmitting: Function,
  ) => {
    const { clubMembers, request, clubId, speechTitle, speechDate } =
      updatedValues;

    const data = {
      event: event?.id || '',
      evaluatorsList: clubMembers.map(({ id }: any) => id),
      evaluationForm: routerState?.id,
      description: request,
      club: clubId,
      speechLog: '',
      speechTitle: speechTitle,
      ...(speechDate && {
        speechDate: parseDate(speechDate).toISOString(),
      }),
      action: 'save-email',
    };

    const formReviewers = getStringWithSeperator(
      clubMembers.map((member: any) => getUserName(member)),
    );

    try {
      await dispatch(shareEvaluationForm(data)).unwrap();
      toast.success(
        `${title} was successfully sent to ${formReviewers}.`,
      );
      setShowRequestForm(false);
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setSubmitting(false);
    }

  };

  const handleFormShare = () => {
      history.push({
        pathname: generatePath(Routes.DIGITAT_EVALUATION_FORM.path, {
          id: routerState?.id,
        }),
        
        state: { ...routerState },
        search: '?type=feedback',
      });
  }

  return (
    <Container>
      <div role="navigation" aria-label="breadcrumbs">
        <NavBreadCrumbs>
          <li>
            <Link to={`${getPlatformName()}/feedback`}>Feedback</Link>
          </li>
          <li>
            <span className='separator'>/</span>
          </li>
          <li>Digital Evaluation Forms</li>
        </NavBreadCrumbs>
      </div>
      {showRequestForm ? 
          <RequestFormHolder>
            <div className="header">
              <Typography variant='h2' component={'h1'} className='fw-700'>Send to My Evaluator</Typography>
              <Typography variant='h2' mb={4} className='fw-400'>{routerState?.title}</Typography>
            </div>
            <ShareFormModal
              title={`Send to My Evaluator: ${routerState?.title}`}
              handleClose={() => setShowRequestForm(false)}
              handleSubmitForm={handleSubmitForm}
              isDirectFeedback={false}
            />
          </RequestFormHolder>
        :
        <SelectionBlock>
          <Typography variant='h2' component={'h1'}  className='fw-700'>Digital Evaluation Form: {routerState?.title}</Typography>
          <SelectionBoxes>
            <Block>
              <IconHolder>
                <ShareEvaluation />
              </IconHolder>
              <div className="holder">
                <Typography variant='h3' component={'h2'} className='fw-700' mb={4}>Complete & Share Evaluation</Typography>
                <Button variant='contained' onClick={() => handleFormShare()}>Share</Button>
              </div>
            </Block>
            <Block>
              <IconHolder>
                <SendEvaluation />
              </IconHolder>
              <div className="holder">
                <Typography variant='h3' component={'h2'} className='fw-700' mb={4}>Send to My Evaluator</Typography>
                <Button variant='contained' onClick={() => setShowRequestForm(true)}>Request</Button>
              </div>
            </Block>
          </SelectionBoxes>
          <SelectionBottomBlock>
            <Typography variant='body1' mb={4}>Downloadable, printable evaluation resources are available on the{' '} 
             <Link
              to={`${generatePath(Routes.DASHBOARD.path, {
                activeSection: 'resources',
                activeSubSection: 'resources-details',
              })}?${new URLSearchParams({
                category: 'Evaluation Resources',
              }).toString()}`}
            >Resources page</Link>.</Typography>
            <Button variant='outlined' onClick={() => history.goBack()} className="btn-back"><ArrowBackIosIcon fontSize='small' /> Back</Button>
          </SelectionBottomBlock>
        </SelectionBlock>
      }
    </Container>
  );
};

export default EvaluationFormSelectionSection;
