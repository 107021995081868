import Loader from 'elements/Loader';
import {
  memo,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

const DocPreview: FunctionComponent<any> = ({ fileName, url }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [frameInterval, setFrameInterval] = useState<any>(null);
  const [incrementalId, setIncrementalId] = useState(1);

  const iFrameUrl = useMemo(() => {
    let encodedUrl = encodeURIComponent(url);
    return `https://docs.google.com/viewer?url=${encodedUrl}&time=${
      new Date().getTime() + Math.floor(Math.random() * 1000000)
    }${incrementalId}&embedded=true`;
  }, [url, incrementalId]);

  const pollRequest = useCallback((isLoaded = false) => {
    if (isLoaded) return false;
    setIncrementalId(incrementalId => incrementalId + 1);
  }, []);

  useEffect(() => {
    pollRequest();
  }, [pollRequest]);

  useEffect(() => {
    if (isLoaded && frameInterval) {
      clearInterval(frameInterval);
    }
    if (!isLoaded && frameInterval === null) {
      const inst = setInterval(() => pollRequest(isLoaded), 4000);
      setFrameInterval(inst);
    }
  }, [frameInterval, isLoaded, pollRequest]);

  if (!isLoaded && frameInterval === null) {
    return <><Loader position='relative' /></>;
  }

  return (
    <iframe
      src={iFrameUrl}
      onLoad={e => {
        setLoaded(true);
      }}
      title={fileName}
      height='100%'
      width='100%'
    />
  );
};


DocPreview.defaultProps = {
  fileName: 'Document',
  url: '',
};

export default memo(DocPreview);
