import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { getUserName } from 'utils/utility';

const parseDateString = (value: any, originalValue: any) => {
  const parsedDate = dayjs(originalValue).isValid()
    ? value
    : dayjs(originalValue).format('MMMM DD, YYYY');

  return parsedDate;
};

export const getValidatioinSchema = (
  questions: any = {},
  isFeedbackForm = false,
) => {
  const questionSchema: any = Object.values(questions).reduce(
    (acc: any, question: any) => ({
      ...acc,
      [`q${question.id}`]: Yup.object({
        answer: Yup.number().required('Field is required'),
        comment: Yup.string(),
      }),
    }),
    {},
  );

  return Yup.object({
    date: Yup.date()
      .transform(parseDateString)
      .typeError('Invalid date format. (MMMM DD, YYYY)')
      .nullable()
      .required('Field is required'),
    excelled: Yup.string().required('Field is required'),
    workOn: Yup.string().required('Field is required'),
    challenge: Yup.string().required('Field is required'),
    questions: Yup.object().shape(questionSchema).required(),
    speechTitle: Yup.string().required('Field is required'),
    ...(isFeedbackForm && {
      member: Yup.number().required('Field is required'),
    }),
    description: Yup.string(),
  });
};

export const QUESTION_OPTIONS = [
  {
    value: '5',
    label: '5 – Exemplary',
  },
  {
    value: '4',
    label: '4 – Excels',
  },
  {
    value: '3',
    label: '3 – Accomplished',
  },
  {
    value: '2',
    label: '2 – Emerging',
  },
  {
    value: '1',
    label: '1 – Developing',
  },
];

export const INITIAL_FORM_FIELDS = {
  clubId: '',
  date: null,
  excelled: '',
  workOn: '',
  challenge: '',
  member: '',
  evaluator: '',
  speechTitle: '',
};

export const getInitialFormValues = (
  data: any,
  isEmptyForm: boolean,
  isFeedbackForm: boolean,
  routerState: any,
  user: any
) => {
  if (isEmpty(data)) {
    return {};
  }

  if (isFeedbackForm) {
    return {
      ...INITIAL_FORM_FIELDS,
      member: routerState?.member?.id,
      evaluator: getUserName(user),
      date: routerState?.speechDate,
      speechTitle: routerState?.speechTitle,
      length: data.length,
      purposeStatement: data.purposeStatement,
      notes: data.notes,
      questions: data.questions.reduce((acc: any, question: any) => {
        return {
          ...acc,
          [`q${question.id}`]: { answer: '', comment: '', ...question },
        };
      }, {}),
    };
  }

  if (isEmptyForm) {
    return {
      ...INITIAL_FORM_FIELDS,
      length: data.length,
      purposeStatement: data.purposeStatement,
      notes: data.notes,
      questions: data.questions.reduce((acc: any, question: any) => {
        return {
          ...acc,
          [`q${question.id}`]: { answer: '', comment: '', ...question },
        };
      }, {}),
    };
  }

  return {
    excelled: data.excelled || '',
    workOn: data.workOn || '',
    challenge: data.challenge || '',
    date: data.speechLog.speechDate ?? null,
    member: getUserName(data.speechLog.learner),
    evaluator: getUserName(data.evaluator),
    speechTitle: data.speechLog.speechTitle,
    length: data.evaluationForm.length,
    purposeStatement: data.evaluationForm.purposeStatement,
    notes: data.evaluationForm.notes,
    questions: data.evaluationForm.questions.reduce(
      (acc: any, question: any) => {
        return {
          ...acc,
          [`q${question.id}`]: {
            answer: '',
            comment: '',
            ...question,
          },
        };
      },
      {},
    ),
    description: data.description,
  };
};
